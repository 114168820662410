import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Автосервіс Prime Auto
			</title>
			<meta name={"description"} content={"Якісний сервіс, якому можна довіряти"} />
			<meta property={"og:title"} content={"Головна | Автосервіс Prime Auto"} />
			<meta property={"og:description"} content={"Якісний сервіс, якому можна довіряти"} />
			<link rel={"shortcut icon"} href={"https://art.flexorise.com/img/4362878.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://art.flexorise.com/img/4362878.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://art.flexorise.com/img/4362878.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://art.flexorise.com/img/4362878.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://art.flexorise.com/img/4362878.png"} />
			<meta name={"msapplication-TileImage"} content={"https://art.flexorise.com/img/1.jpg"} />
			
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="Hero-16">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="0px 80px 0px 0px"
					lg-padding="0px 0 0px 0px"
					lg-margin="0px 0px 50px 0px"
					align-items="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Автосервіс Prime Auto
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
					В автосервісі Prime Auto Care Center ми прагнемо надавати виняткові автомобільні послуги з акцентом на надійність, якість та задоволеність клієнтів. Наша команда досвідчених професіоналів гарантує, що ваш автомобіль отримає найкращий догляд, незалежно від того, чи потрібне вам планове технічне обслуговування, чи капітальний ремонт.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-self="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="#774c3a"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="#774c3a"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							sm-width="100%"
							href="/contact-us"
							type="link"
							text-decoration-line="initial"
						>
							Зв'яжіться з нами
						</Button>
					</Box>
				</Box>
				<Image
					src="https://art.flexorise.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Про автосервіс
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
			Автосервіс "Prime" - це універсальне рішення для всіх ваших автомобільних потреб. Наш сучасний центр обладнаний новітніми інструментами та технологіями, що дозволяє нам ефективно діагностувати та ремонтувати ваш автомобіль. Ми пишаємося своєю прозорістю, забезпечуючи чітке та чесне інформування про послуги, яких потребує ваш автомобіль. Прагнучи досконалості, ми прагнемо будувати довготривалі відносини з нашими клієнтами, надаючи першокласний сервіс кожного разу, коли ви звертаєтесь до нас.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image src="https://art.flexorise.com/img/2.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://art.flexorise.com/img/3.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://art.flexorise.com/img/4.jpg" width="320px" max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 16px 0px" font="--headline2" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Комплексні послуги для вашого автомобіля
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="--lead">
				В автосервісі "Prime Auto ми пропонуємо широкий спектр послуг для забезпечення безперебійної роботи вашого автомобіля. Від заміни мастила та ремонту гальм до діагностики двигуна та заміни шин - наші кваліфіковані фахівці навчені робити все, що завгодно. Ми розуміємо, що ваш автомобіль є важливою частиною вашого повсякденного життя, і ми прагнемо повернути вас на дорогу якомога швидше без шкоди для якості.
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://art.flexorise.com/img/5.jpg"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Досвідчені фахівці
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Наша команда складається з сертифікованих фахівців з багаторічним досвідом роботи в автомобільній промисловості, що гарантує, що ваш автомобіль знаходиться в надійних руках.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://art.flexorise.com/img/6.jpg"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Передові технології:
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						Ми використовуємо новітні діагностичні інструменти та обладнання для точного виявлення та усунення проблем, мінімізуючи час простою та максимізуючи ефективність.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://art.flexorise.com/img/7.jpg"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
						Клієнтоорієнтований підхід
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
						Ваше задоволення - наш пріоритет. Ми прислухаємося до ваших побажань і пропонуємо рішення, адаптовані до ваших конкретних потреб.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://art.flexorise.com/img/8.jpg"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://art.flexorise.com/img/9.jpg"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://art.flexorise.com/img/10.jpg"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://art.flexorise.com/img/11.jpg"
							position="absolute"
							width="100%"
							left={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://art.flexorise.com/img/12.jpg"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});